import React from "react";
import equipment from "../assets/images/equipment.png";
import "../assets/css/bestTechnology.css";

export const BestTechnology = (data) => {
  const url = window.location.pathname;
  const phone = data.data.phoneNumber

  return (
    <div className="technology">
      <h2>TRABAJAMOS CON LA MEJOR TECNOLOGÍA</h2>
      <p>
        Brindamos un servicio de protección integral las 24 horas con la
        <b> tecnología más moderna</b> y monitoreamos la operación de los
        sistemas de detección de intrusos de nuestros clientes.
      </p>
      <img loading="lazy" src={equipment} alt="Equipment" />
      <br />
      { url === "/promo" ? (
        <a href={`tel:${phone}`}>
          <button value="Me interesa">Me interesa</button>
        </a>
      ):(
        <a href={`tel:${phone}`}>
          <button value="Me interesa">Me interesa</button>
        </a>
      )}
    </div>
  );
};

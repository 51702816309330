import Carousel from "react-bootstrap/Carousel";

import serviciosAlarma1 from "../assets/images/serviciosAlarma1.png";
import serviciosAlarma2 from "../assets/images/serviciosAlarma2.png";
import serviciosAlarma3 from "../assets/images/serviciosAlarma3.png";
import serviciosAlarma1Mobile from "../assets/images/serviciosAlarma1Mobile.png";
import serviciosAlarma2Mobile from "../assets/images/serviciosAlarma2Mobile.png";
import serviciosAlarma3Mobile from "../assets/images/serviciosAlarma3Mobile.png";

import "../assets/css/slider.css";

export const SliderServices = ({ handlerPopUpForm, setGetText }) => {
  const text =
    "Averigua cuál es la mejor alarma para tu casa, departamento o negocio";
  setGetText(text);
  return (
    <div className="slider-title">
      <div className="slider-services-title">
        Nos adaptamos a tus necesidades
      </div>
      <div className="carousel">
        <Carousel>
          <Carousel.Item>
            {window.screen.width < 1001 ? (
              <img
                loading="lazy"
                src={serviciosAlarma1Mobile}
                alt="serviciosAlarma"
                style={{ width: "100%", height: "120vw" }}
                onClick={() => handlerPopUpForm(text)}
              />
            ) : (
              <img
                loading="lazy"
                src={serviciosAlarma1}
                alt="serviciosAlarma"
                className="d-block w-100"
                onClick={() => handlerPopUpForm(text)}
              />
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.screen.width < 1001 ? (
              <img
                loading="lazy"
                src={serviciosAlarma2Mobile}
                alt="serviciosAlarma"
                style={{ width: "100%", height: "120vw" }}
                onClick={() => handlerPopUpForm(text)}
              />
            ) : (
              <img
                loading="lazy"
                src={serviciosAlarma2}
                alt="serviciosAlarma"
                className="d-block w-100"
                onClick={() => handlerPopUpForm(text)}
              />
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.screen.width < 1001 ? (
              <img
                loading="lazy"
                src={serviciosAlarma3Mobile}
                alt="serviciosAlarma"
                style={{ width: "100%", height: "120vw" }}
                onClick={() => handlerPopUpForm(text)}
              />
            ) : (
              <img
                loading="lazy"
                src={serviciosAlarma3}
                alt="serviciosAlarma"
                className="d-block w-100"
                onClick={() => handlerPopUpForm(text)}
              />
            )}
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

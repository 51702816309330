import React from "react";
import Carousel from "react-bootstrap/Carousel";

import promocionAlarma1 from "../assets/images/promocionAlarma1.png";
import promocionAlarma2 from "../assets/images/promocionAlarma2.png";
import promocionAlarma3 from "../assets/images/promocionAlarma3.png";
import promocionAlarma4 from "../assets/images/promocionAlarma4.png";
import promocionAlarma5 from "../assets/images/promocionAlarma5.png";
import promocionAlarma6 from "../assets/images/promocionAlarma6.png";
import promocionAlarma1Mobile from "../assets/images/promocionAlarma1Mobile.png";
import promocionAlarma2Mobile from "../assets/images/promocionAlarma2Mobile.png";
import promocionAlarma3Mobile from "../assets/images/promocionAlarma3Mobile.png";
import promocionAlarma4Mobile from "../assets/images/promocionAlarma4Mobile.png";
import promocionAlarma5Mobile from "../assets/images/promocionAlarma5Mobile.png";
import promocionAlarma6Mobile from "../assets/images/promocionAlarma6Mobile.png";

import "../assets/css/slider.css";

export const Slider = ({ setGetText, handlerPopUpForm }) => {
  const text = "Recibe asesoramiento sin cargo";
  setGetText(text);

  return (
    <div className="slider-title">
      <div className="carousel">
        <Carousel>
          <Carousel.Item>
            {window.innerWidth < 1001 ? (
              <img
                loading="lazy"
                src={promocionAlarma1Mobile}
                alt="promocionAlarma"
                style={{ width: "100%", height: "130vw" }}
                onClick={() => handlerPopUpForm(text)}
              />
            ) : (
              <img
                loading="lazy"
                src={promocionAlarma1}
                alt="promocionAlarma"
                className="d-block w-100"
                onClick={() => handlerPopUpForm(text)}
              />
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.innerWidth < 1001 ? (
              <img
                loading="lazy"
                src={promocionAlarma2Mobile}
                alt="promocionAlarma"
                style={{ width: "100%", height: "130vw" }}
                onClick={() => handlerPopUpForm(text)}
              />
            ) : (
              <img
                loading="lazy"
                src={promocionAlarma2}
                alt="promocionAlarma"
                className="d-block w-100"
              />
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.innerWidth < 1001 ? (
              <img
                loading="lazy"
                src={promocionAlarma3Mobile}
                alt="promocionAlarma"
                style={{ width: "100%", height: "130vw" }}
                onClick={() => handlerPopUpForm(text)}
              />
            ) : (
              <img
                loading="lazy"
                src={promocionAlarma3}
                alt="promocionAlarma"
                className="d-block w-100"
                onClick={() => handlerPopUpForm(text)}
              />
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.innerWidth < 1001 ? (
              <img
                loading="lazy"
                src={promocionAlarma4Mobile}
                alt="promocionAlarma"
                style={{ width: "100%", height: "130vw" }}
                onClick={() => handlerPopUpForm(text)}
              />
            ) : (
              <img
                loading="lazy"
                src={promocionAlarma4}
                alt="promocionAlarma"
                className="d-block w-100"
                onClick={() => handlerPopUpForm(text)}
              />
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.innerWidth < 1001 ? (
              <img
                loading="lazy"
                src={promocionAlarma5Mobile}
                alt="promocionAlarma"
                style={{ width: "100%", height: "130vw" }}
                onClick={() => handlerPopUpForm(text)}
              />
            ) : (
              <img
                loading="lazy"
                src={promocionAlarma5}
                alt="promocionAlarma"
                className="d-block w-100"
              />
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.innerWidth < 1001 ? (
              <img
                loading="lazy"
                src={promocionAlarma6Mobile}
                alt="promocionAlarma"
                style={{ width: "100%", height: "130vw" }}
                onClick={() => handlerPopUpForm(text)}
              />
            ) : (
              <img
                loading="lazy"
                src={promocionAlarma6}
                alt="promocionAlarma"
                className="d-block w-100"
                onClick={() => handlerPopUpForm(text)}
              />
            )}
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

import React from "react";
import alarm from "../assets/images/alarm.svg";
import monitoreo from "../assets/images/monitoreo.svg";
import vendor from "../assets/images/vendor.svg";
import policeman from "../assets/images/policeman.svg";
import number1 from "../assets/images/number1.svg";
import number2 from "../assets/images/number2.svg";
import number3 from "../assets/images/number3.svg";
import number4 from "../assets/images/number4.svg";
import "../assets/css/instructions.css";

export const Instructions = () => {
  return (
    <div className="instructions">
      <h2>CÓMO FUNCIONA</h2>
      <h3>
        Ante una emergencia cada segundo es importante, por eso entregamos un
        servicio de <b>rápida respuesta.</b>
      </h3>
      <div className="instructions-container">
        <div className="instruction">
          <img
            loading="lazy"
            src={number1}
            alt="Number 1"
            className="numbers"
          />
          <img
            loading="lazy"
            src={alarm}
            alt="Leaders in security"
            className="images"
          />
          <div className="instruction-info">
            <h4>ACTIVACIÓN ALARMA</h4>
            <p>
              Se activa la alarma y una <br />
              señal es transmitida inmediatamente hasta la Central ADT.
            </p>
          </div>
        </div>
        <div className="instruction">
          <img
            loading="lazy"
            src={number2}
            alt="Number 2"
            className="numbers"
          />
          <img
            loading="lazy"
            src={monitoreo}
            alt="Response"
            className="images"
          />
          <div className="instruction-info">
            <h4>RESPUESTA</h4>
            <p>
              En el momento que la Central ADT recibe la señal, ésta se verifica
              para eliminar falsas alarmas.
            </p>
          </div>
        </div>
        <div className="instruction">
          <img
            loading="lazy"
            src={number3}
            alt="Number 3"
            className="numbers"
          />
          <img
            loading="lazy"
            src={vendor}
            alt="Contact with company"
            className="images"
          />
          <div className="instruction-info">
            <h4>CONTACTO CON LA EMPRESA</h4>
            <p>
              ADT contacta al titular de la cuenta para confirmar que todo se
              encuentra bien o si es necesario asistencia.
            </p>
          </div>
        </div>
        <div className="instruction">
          <img
            loading="lazy"
            src={number4}
            alt="Number 4"
            className="numbers"
          />
          <img
            loading="lazy"
            src={policeman}
            alt="Emergency"
            className="images"
          />
          <div className="instruction-info">
            <h4>EMERGENCIAS</h4>
            <p>
              En caso de emergencia, <br />
              ADT se contactará con la policía, servicios de asistencia médica
              y/o bomberos.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

import Carousel from "react-bootstrap/Carousel";

import kitAlarma1 from "../assets/images/kitAlarma1.png";
import kitAlarma2 from "../assets/images/kitAlarma2.png";
import kitAlarma1Mobile from "../assets/images/kitAlarma1Mobile.png";
import kitAlarma2Mobile from "../assets/images/kitAlarma2Mobile.png";

import "../assets/css/slider.css";
import { useState } from "react";

export const SliderProducts = ({ setGetText, handlerPopUpForm }) => {
  const [index, setIndex] = useState(0);
  const [text, setText] = useState("Panel inteligente");
  setGetText(text);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
    selectedIndex === 0
      ? setText("Panel inteligente")
      : setText("Kit inalámbrico 2.2");
  };
  return (
    <div className="slider-title">
      <h2 className="slider-products-title">TECNOLOGÍA EN NUESTROS SISTEMAS</h2>

      <div className="carousel">
        <Carousel activeIndex={index} onSelect={handleSelect}>
          <Carousel.Item>
            {window.screen.width < 1001 ? (
              <img
                loading="lazy"
                src={kitAlarma1Mobile}
                alt="kitAlarma"
                style={{ width: "100%", height: "120vw" }}
              />
            ) : (
              <img
                loading="lazy"
                src={kitAlarma1}
                alt="kitAlarma"
                className="d-block w-100"
                onClick={() => handlerPopUpForm(text)}
              />
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.screen.width < 1001 ? (
              <img
                loading="lazy"
                src={kitAlarma2Mobile}
                alt="kitAlarma"
                style={{ width: "100%", height: "120vw" }}
                onClick={() => handlerPopUpForm(text)}
              />
            ) : (
              <img
                loading="lazy"
                src={kitAlarma2}
                alt="kitAlarma"
                className="d-block w-100"
                onClick={() => handlerPopUpForm(text)}
              />
            )}
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

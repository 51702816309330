import React from "react";
import shield from "../assets/images/shield.svg";
import light from "../assets/images/light.svg";
import camera from "../assets/images/camera.svg";
import eye from "../assets/images/eye.svg";
import "../assets/css/services.css";

export const Services = () => {
  return (
    <div className="services">
      <h2>PORQUÉ ELEGIRNOS</h2>
      <h3>
        ADT ARGENTINA es la empresa líder en seguridad y monitoreo que brinda la
        protección que tu hogar o negocio necesita.
      </h3>
      <div className="items-container">
        <div className="item">
          <img src={light} alt="Leaders in security" />
          <div className="item-info">
            <h3>Líderes en seguridad y monitoreo</h3>
            <p>
              Poseemos años de experiencia protegiendo hogares y negocios de
              todo el país.
            </p>
          </div>
        </div>

        <div className="item">
          <img src={shield} alt="Security 24/7" />
          <div className="item-info">
            <h3>Seguridad 24/7</h3>
            <p>
              Brindamos un completo sistema de seguridad con video online que
              permite automatizar tu hogar o negocio.
            </p>
          </div>
        </div>

        <div className="item">
          <img src={camera} alt="High quality" />
          <div className="item-info">
            <h3>La más alta calidad</h3>
            <p>
              Contamos con la mejor tecnología que se adecúa a las necesidades
              de tu empresa
            </p>
          </div>
        </div>

        <div className="item">
          <img src={eye} alt="Cars for verification" />
          <div className="item-info">
            <h3>Móviles de verificación</h3>
            <p>
              Siempre listos para actuar, tenemos la mejor cobertura gracias a
              nuestra flota de más de 60 móviles de verificación.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

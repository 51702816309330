import React from "react";
import whatsapp from "../assets/images/whatsapp.svg";
import "../assets/css/whatsapp.css";

export const WhatsApp = () => {
  return (
    <div className="whatsapp">
      {window.screen.width < 900 ? (
        <a
          href="https://wa.me/5493516631614?text=Quiero asesoramiento para contratar ADT"
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsapp} alt="whatsapp" />
        </a>
      ) : (
        <a
          href="http://web.whatsapp.com/send?phone=5493516631614&text=Quiero asesoramiento para contratar ADT"
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsapp} alt="whatsapp" />
        </a>
      )}
    </div>
  );
};
